<template>
  <div>
    <header-slot />
    <b-nav card-header pills class="m-0">
      <b-nav-item
        :to="{ name: $route.matched[1].meta.routePending }"
        exact
        :exact-active-class="statusApp == 1 ? 'active' : ''"
        :link-classes="['px-3', bgTabsNavs]"
        >
          PENDING
          <span
            v-if="G_PENDING_OTHER_SERVICES > 0"
            class="ml-2"
          >
            <feather-icon
              icon
              :badge="G_PENDING_OTHER_SERVICES > 99 ? '99+' : G_PENDING_OTHER_SERVICES"
              badge-classes="badge-danger"
            />
          </span>
      </b-nav-item>
      <b-nav-item
        :to="{ name: $route.matched[1].meta.routeCompleted }"
        exact
        :exact-active-class="statusApp == 2 ? 'active' : ''"
        :link-classes="['px-3', bgTabsNavs]"
        :active="isInCompletedTab"
        >COMPLETED
      </b-nav-item>
      <b-nav-item
        :to="{ name: $route.matched[1].meta.routeExpired }"
        exact
        :exact-active-class="statusApp == 4 ? 'active' : ''"
        :link-classes="['px-3', bgTabsNavs]"
        >EXPIRED
      </b-nav-item>
    </b-nav>
    <b-card
      no-body
      class="border-top-primary border-3 border-table-radius px-0"
    >
      <router-view :key="$route.name" :program_id="programId" />
    </b-card>

    <create-application-modal
      v-if="ActiveCreateApplicationModal"
      @close="closeCreateApplicationModal"
      @refreshTable="refreshTable"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { canNavigateDirect } from "@/libs/acl/routeProtectionDirect";
import { getUserData } from "@/auth/utils";
import OtherServicesService from '@/views/commons/components/other-services/service/appointment.service'

export default {
  beforeRouteEnter(to, from, next) {
    const userData = getUserData();
    if (!canNavigateDirect(to, userData.arrRoles, userData.eng_soft)) {
      return next({ name: "misc-not-authorized" });
    } else {
      return next();
    }
  },
  data() {
    return {
      ActiveCreateApplicationModal: false,
    };
  },
  async created(){
    await this.A_GET_PENDING_OTHER_SERVICES();
  },
  computed: {
    programId() {
      return this.$route.matched[1].meta.program;
    },
    statusApp() {
      return this.$route.meta.statusApp;
    },
    isInCompletedTab() {
      const { routeSuccessfull, routeUnsuccessful } =
        this.$route.matched[1].meta;
      return [routeSuccessfull, routeUnsuccessful].includes(this.$route.name);
    },
    ...mapGetters({
      currentUser: 'auth/currentUser',
      G_PENDING_OTHER_SERVICES: 'OtherServices/G_PENDING_OTHER_SERVICES',
    }),
  },
  methods: {
    ...mapMutations({
      M_PENDING_OTHER_SERVICES: 'OtherServices/M_PENDING_OTHER_SERVICES',
    }),
    ...mapActions({
      A_GET_PENDING_OTHER_SERVICES: 'OtherServices/A_GET_PENDING_OTHER_SERVICES'
    }),
  }
};
</script>
<style>
td {
  padding-top: 10px !important;
  padding-left: 10px !important;
  padding-bottom: 5px !important;
  padding-right: 0 !important;
}
.tab {
  transform: translateY(-1rem);
}
.container-fluid {
  padding: 0;
}
</style>
